<template>
    <div class="navmb">
        <div class="flex flexa">
        <el-icon color="#FF6A00" size="20" class="astyle bgshadow" @click="$router.back()"><Back /></el-icon> &nbsp; &nbsp;
            <span class="">分类管理</span>
        </div>
    </div>
    <div class="bgf manage1">
        <div class="padd20 mart20">
            <el-button type="primary" class="w115 " @click="type.show = true">添加分类</el-button>
        </div>
    </div>
    <div class="bgf mart20">
        <el-table :data="list" style="width: 100%" v-loading="loading">
          <el-table-column
            fixed
            label="分类名称"
            prop="categoryTitle"
            class-name="padl20 padr20"
            :show-overflow-tooltip="{ effect: 'light' }"
            min-width="234"
          >
          </el-table-column>
          <el-table-column
            min-width="140"
            prop="docCount"
            label="分类下文章"
            :show-overflow-tooltip="{ effect: 'light' }"
          >
          </el-table-column>
          <el-table-column
            min-width="140"
            prop="createTime"
            label="添加时间"
            :show-overflow-tooltip="{ effect: 'light' }"
          >
          </el-table-column>
          <el-table-column
            min-width="140"
            align="center"
            label="操作"
          >
            <template #="{ row }">
                <el-button @click="toAdd(row)" text type="primary">编辑</el-button>
                <el-divider direction="vertical" />
                <el-button :loading="row.deleteLoading??false" @click="deleteRow(row)" text type="danger">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="h70 pad20 flexab w100 borbox">
            <p class="font10 cola4">共 {{total}} 条</p>
            <el-pagination
                v-model:currentPage="param.pageNumber"
                @update:page-size="param.pageSize = $event;getList(1)"
                @update:current-page="getList"
                :page-sizes="[10, 15, 20, 25]"
                :page-size="param.pageSize"
                layout=" sizes, prev , jumper , next"
                :total="total">
            </el-pagination>
        </div>
    </div>
    <el-dialog :close-on-click-modal="false"
        v-model="type.show" :title="title"
        width="650px"
        align-center
        @closed="(type.form = {}) && $refs.submitForm.resetFields()"
    >
        <el-form
            :model="type.form"
            ref="submitForm"
            label-width="120px"
            :rules="type.rules"
        >
            <el-form-item label="分类名称：" prop="categoryTitle">
                <el-input v-model="type.form.categoryTitle" placeholder="请输入分类名称" class="w270" />
            </el-form-item>
        </el-form>
        <div class="tc">
            <el-button
                :disabled="type.fullscreenLoading"
                :loading="type.submitLoading"
                @click="submit" class="w115 mart20" type="primary">确定</el-button>
            <el-button
                :loading="type.submitLoading"
                @click="type.show = false" class="w115 mart20">取消</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { Back } from '@element-plus/icons-vue'
export default {
    setup() {
        return {}
    },
    computed: {
        title() {
            return this.type.form.id ? '编辑分类': '新增分类'
        }
    },
    components: {
        Back,
    },
    data() {
        return {
            type: {
                show: false,
                form: {},
                rules: {
                    categoryTitle: [{ required: true, message: '请输入分类名称', trigger: 'blur' }],
                },
                submitLoading: false
            },
            param: {
                pageNumber: 1,
                pageSize: 10

            },
            loading: false,
            list: [],
            total: 0
            
        }
    },
    methods: {
        toAdd(row) {
            this.type.form = Object.assign({}, row)
            this.type.show = true
        },
        async submit() {
            try {
                this.type.submitLoading = this.type.fullscreenLoading = true
                await this.$refs['submitForm'].validate()
                const result = await this.$api.know.addUserDocumentCategories(this.type.form)
                if (result) {
                    this.$message.success('操作成功')
                    this.type.show = false
                    this.getList(1)
                }
            } catch (e) {
                return console.log(e)
            } finally {
                this.type.submitLoading = this.type.fullscreenLoading = false
            }
        },
        async deleteRow(row) {
            try {
                await this.$confirm(
                    "确认删除吗",
                    "信息",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                    }
                )
                row.deleteLoading = true
                const result = await this.$api.know.deleteUserDocumentCategory({
                    id: row.id,
                })
                if (result) {
                    this.getList(1)
                }
            } catch (e) {
                return console.log(e)
            } finally {
                row.deleteLoading = false
            }
        },
        async getList(pageNumber){
            try {
                pageNumber && (this.param.pageNumber = pageNumber)
                this.loading = true
                const { records, total } = await this.$api.know.userDocCategorylist(this.param)
                this.list = records
                this.total = total
            } catch (e) {
                return console.log(e)
            } finally {
                this.loading = false
            }
        },
    },
    mounted() {
        this.getList()
    },
}
</script>
<style scoped>
</style>